/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'person_step_icon': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<mask id="svgicon_person_step_icon_a" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24"><path pid="0" d="M19.086 1.413h.001c1.524.592 2.474 1.735 3.05 2.757a7.958 7.958 0 01.715 1.73c.013.046.022.082.027.105l.006.024v.005h0l.002.007.002.007c.486 2.018.633 4.093.608 5.958h0v.013c.025 1.835-.147 3.912-.634 5.959h0l-.002.012h0l-.001.005-.006.025a6.328 6.328 0 01-.15.509 7.963 7.963 0 01-.592 1.325c-.575 1.023-1.526 2.165-3.05 2.758h0l-.004.001c-1.471.585-4.247.862-7.058.887-2.804-.025-5.586-.326-7.064-.889-1.523-.593-2.473-1.735-3.048-2.757a7.955 7.955 0 01-.715-1.729 3.857 3.857 0 01-.027-.105l-.006-.025v-.004h0-.001v-.001l-.002-.008-.002-.01c-.51-2.04-.657-4.113-.632-5.978h0v-.013c-.025-1.835.146-3.912.633-5.959h0l.003-.012h0v-.001l.001-.005.006-.024.027-.106c.025-.093.065-.232.123-.403.117-.344.306-.816.592-1.326.575-1.021 1.525-2.164 3.048-2.756C6.414.825 9.196.525 12 .5c2.805.025 5.586.326 7.086.913z" _fill="#16161C" _stroke="#A7A7B8"/></mask><g mask="url(#svgicon_person_step_icon_a)"><path pid="1" _fill="#8261FC" d="M0 0h24v24H0z"/><mask id="svgicon_person_step_icon_b" maskUnits="userSpaceOnUse" x="4" y="4" width="16" height="16"><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M15.12 8.999c0 1.6-1.37 2.945-3.12 2.945S8.88 10.6 8.88 8.999c0-1.6 1.37-2.945 3.12-2.945S15.12 7.398 15.12 9zm-1.07 3.915zm-3.656-.1zM16.58 9c0 1.584-.879 2.965-2.188 3.732 2.845.83 4.985 3.114 4.985 5.931a.723.723 0 01-.73.716.723.723 0 01-.731-.716c0-2.57-2.537-4.838-5.917-4.838s-5.917 2.268-5.917 4.838a.723.723 0 01-.73.716.723.723 0 01-.73-.716c0-2.817 2.14-5.1 4.984-5.93-1.309-.768-2.188-2.149-2.188-3.733 0-2.43 2.063-4.376 4.581-4.376 2.518 0 4.581 1.947 4.581 4.376z" _fill="#898B9B"/></mask><g mask="url(#svgicon_person_step_icon_b)"><path pid="3" _fill="#fff" d="M4 4h16v16H4z"/></g></g>'
  }
})
